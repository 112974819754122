import React from "react";

const IndetificationDocument = () => {

    const feature = [{
        title: "Passport (showing all four corners) ",
        kuda: "Original – photograph/scanned copy"
    },
    {
        title: <span>Full or provisional UK/EEA photocard driving licence</span>,
        kuda: "Original – photograph/scanned copy"
    },
    {
        title: <span>National Identity Card (only acceptable for EEA <br />(European Economic Area) or Swiss National
        customers) <br /> bearing a photograph of the applicant and showing <br/> the front and back</span>,
        kuda: "Original – photograph/scanned copy"
    }, {
        title: <span>EU/EEA or UK Residence permit/card <br/> (issues by the Home Office)</span>,
        kuda: "Original – photograph/scanned copy"
    }, {
        title: "Northern Ireland voter's card ",
        kuda: "Original – photograph/scanned copy"
    },
    ]

    const documents = [{
        title: <span>Utility bill (Gas, electricity, oil, home broadband <br /> or water bill for the current billing period) </span>,
        kuda: "Original – photograph/scanned copy"
    },
    {
        title: <span>Landline (telephone) bill (mobile phone bills are <br/> not acceptable)</span>,
        kuda: "Original – photograph/scanned copy"
    },
    {
        title: <span>TV, satellite, or cable bill dated within the last <br /> three (3) months </span>,
        kuda: "Original – photograph/scanned copy"
    }, {
        title: <span>Bank, Building Society, Credit Union, or Credit Card <br /> statement (show your recent transactions)
        dated <br /> within the last 3 months</span>,
        kuda: "Original – photograph/scanned copy"
    }, {
        title: <span>Mortgage statement from a recognised lender <br /> (dated within the last 12 months) </span>,
        kuda: "Original – photograph/scanned copy"
    },
    {
        title: <span>Life assurance/life insurance policy, dated within <br/> the last three (3) months but outside of
        cancellation period</span>,
        kuda: "Original – photograph/scanned copy"
    },
     {
        title: <span>A local council tax bill for the current billing/fiscal year</span>,
        kuda: "Original – photograph/scanned copy"
    }, 
    {
        title: <span>HM Revenue & Customs documentation (PAYE <br/> Coding Notice/Tax  Notification/SelfAssessment/<br/>Statement of Account  or NI contributions bill valid <br/> for the current tax year issued
        by Job Centre Plus) <br/> dated within the last three (3) months. <br/> We do not accept P45 or P60 forms</span>,
        kuda: "Original – photograph/scanned copy"
    },
     {
        title: <span>Current Tenancy Agreement, from a local <br/> council or a recognised letting agency</span>,
        kuda: "Original – photograph/scanned copy"
    },
     {
        title: <span>Home or Motor insurance certificate, valid for<br/> the current year but outside of cancellation <br/>
        period – we cannot accept cover notes</span>,
        kuda: "Original – photograph/scanned copy"
    }, {
        title: <span>Notification letter from Benefits Agency/Local <br/> Authority confirming your right to benefits <br/>
        (Department for Works and Pensions, Jobcentre <br/> Plus, Benefits Agency or Veterans Agency), <br/>
        dated within the last 12 months</span>,
        kuda: "Original – photograph/scanned copy"
    }, {
        title: <span>Vehicle registration document (dated within <br/> the last 12 months) </span>,
        kuda: "Original – photograph/scanned copy"
    }, 
    ]

    // const documentss = [{
    //     title: "UK driving licence (Photocard & Counterpart)",
    //     kuda: "Photocopy"
    // },
    // ]

    return (
        <div className="kuda-section kuda-standard--section kuda-choose--freedom" >
            <div className="kuda-section--inner">
                <div className="kuda-section--100">
                    <h1 className="kuda-section--heading text-center text-xlbold color-primary title-bottom--spacing">
                        One easy account, several ID options.
                    </h1>
                    <p className="color-black kuda-section--max identification--max text-center">
                        Please provide one document from both the Identification and Address lists below. Identification Documents need to be within the expiry dates and all Address Documents must be dated within the last three calendar months.  The same document cannot be provided twice.
                    </p>
                    <div className="flex justify-center kuda-plan--wrap">
                        <div className="plan-full">
                            <div className="plan-details">

                                <div className="plan-features">
                                    <div className="plan-heading flex">
                                        <div className="document-single">
                                            <h2 className="card-main--heading color-black text-xlbold">Identification Documents </h2>
                                        </div>
                                    </div>

                                    {feature.map((item, i) =>
                                        <div className="document-single--wrap first-document justify-between" key={i}>
                                            <div className="document-single">
                                                <p className="feature-summary color-black text-bold flex align-flex-start">
                                                    <span className="feature-text height-full">{item.title}</span>
                                                </p>
                                            </div>
                                            {/* <div className="plan-single">
                                                <SingleFeatureNegative text={item.other} />
                                            </div> */}
                                            <div className="document-single">
                                                <span className="color-black height-full">{item.kuda}</span>
                                            </div>
                                        </div>)
                                    }

                                    <div className="plan-heading flex">
                                        <div className="document-single mt-5">
                                            <h2 className="card-main--heading color-black text-xlbold">Address Documents</h2>
                                        </div>
                                    </div>

                                    {documents.map((item, i) =>
                                        <div className="document-single--wrap justify-between" key={i}>
                                            <div className="document-single">
                                                <p className="feature-summary color-black text-bold flex align-flex-start">
                                                    <span className="feature-text height-full">{item.title}</span>
                                                </p>
                                            </div>
                                            <div className="document-single">
                                                <span className="color-black height-full">{item.kuda}</span>
                                            </div>
                                        </div>)
                                    }

                                    <div className="plan-heading flex border-topp">
                                        <div className=" mt-5">
                                            <h2 className="card-main--heading color-black text-xlbold f-16">A UK driving licence (Photocard) - This document can only be accepted if not already provided for identity verification purposes.</h2>
                                        </div>
                                    </div>

                                    {/* {documentss.map((item, i) =>
                                        <div className="document-single--wrap justify-between" key={i}>
                                            <div className="">
                                                <p className="feature-summary color-black text-bold flex align-flex-start">
                                                    <span className="feature-text height-full">{item.title}</span>
                                                </p>
                                            </div>
                                            <div className="document-single">
                                                <span className="color-black height-full">{item.kuda}</span>
                                            </div>
                                        </div>)
                                    } */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndetificationDocument;
