import React, { useState } from "react";
import Lottie from 'react-lottie';
import VizSensor from 'react-visibility-sensor';
import Goto from "../general/goto";

const AnimationTextRight = (props) => {

    const [state, setState] = useState({
        isStopped: true,
        inView: false,
        animation: ""
    });

    const defaultOptions = {
        loop: false,
        autoplay: false,
        animationData: state.animation,
        rendererSettings: {
            preserveAspectRatio: 'none',
            progressiveLoad: false,
            className: 'your-money--illustration'
        }
    };

    const scrollTo = () => {
        document.querySelector(`.${props.isToScroll}`).scrollIntoView();
    }

    // useEffect(() => {
    //     import(`../../../../assets/animations/${props.animationUrl}/data.json`).then(background => {
    //         setState(prevState => ({
    //             ...prevState,
    //             animation: background,
    //             isStopped: true
    //         }))
    //     })
    // }, [])

    return (
        <div className="kuda-section" >
            <div className="kuda-section--inner flex justify-between align-center mobile-reverse">
                <div className="kuda-section--55">
                    <VizSensor onChange={() => {
                        setState(prevState => ({
                            ...prevState,
                            isStopped: false
                        }))
                    }} partialVisibility={true} offset={{ bottom: 230 }}>
                        <div className="kuda-section--illustration not-centered">
                             {props.isNotAnimated ? (
                                <>
                                    {state.animation !== "" ? (
                                        <Lottie
                                            options={defaultOptions}
                                            isStopped={state.isStopped}
                                        />
                                    ) : (
                                        props.fallback
                                    )}
                                </>
                            ) : (
                                props.image
                            )}
                        </div>
                    </VizSensor>
                </div>
                <div className="kuda-section--45 kuda-text--right">
                    <div className="kuda-section--wrap">
                        <h1 className="kuda-section--heading text-xlbold color-primary title-bottom--spacing">
                            {props.title}
                        </h1>
                        <p className="color-black kuda-section--max">
                            {props.subtitle}
                        </p>
                        {typeof props.buttonName !== "undefined" &&
                            (
                                typeof props.isExternal !== "undefined" ? <Goto to={props.buttonUrl} name={props.buttonName} isExternal={true} /> :
                                    typeof props.isToScroll !== "undefined" ?
                                        <Goto to={props.buttonUrl} name={props.buttonName} isToScroll={true} click={scrollTo} isWebButton={props.isWebButton}/>
                                        :
                                        <Goto to={props.buttonUrl} name={props.buttonName} isWebButton={props.isWebButton}/>)}
                    </div>
                </div>
            </div>
        </div>


    )
}

export default AnimationTextRight;
