import React, { useEffect, Fragment } from "react"
import PhoneBlur from "../../../../../assets/img/circle.inline.svg"
import AccountBlur from "../../../../../assets/img/blur/account-blur.inline.svg"
import AccountIcon from "../../../../../assets/img/uk-transfer.inline.svg"
import InstantIcon from "../../../../../assets/img/uk-instant-account.inline.svg"
import ReceiveIcon from "../../../../../assets/img/uk-verify.inline.svg"
import AnimationTextRight from "../animationTextRight"
import HomeHeaderFold from "../sections/homeHeaderFold"
import { scrollToElement } from "../../../../utility/utils"
import IndetificationDocument from "../sections/identificationDocument"
import AnimationTextLeft from "../../general/animationTextLeft"
import CTAUk from "../../general/ctaUk"

const entryContent = {
    title: "Get started with Kuda right away.",
    subtitle: (
        <span>
            Open a secure Kuda account in minutes, start using it immediately.
        </span>
    ),
}

const AccountUk = () => {
    const yourMoney = {
        title: "Open, add money and make transfers.",
        subtitle:
            " Open an account with us quickly with a valid ID and proof of UK residence.",
        buttonName: "Join Kuda",
        buttonUrl: "/joinKuda/",
        animation: "money-manage",
    }

    const phoneDebit = {
        title: (
            <span>
                Important notes
            </span>
        ),
        subtitle:
            <span>
                Please read if you are providing identity documents:

                <li className="mt-4 line-height-1">
                    Any photocopied documents must be clearly readable.
                </li>
                <li className="line-height-1">
                    Your passport, driving license, or national ID card must be within the expiry date.
                </li>
                <li className="line-height-1">
                    All documents listed {" "}
                     must be within 3 months old or will be considered invalid.
                </li>
            </span>,
        // buttonName: "Join Kuda",
        buttonUrl: "Send money with Kuda",
        animation: "simpler-life",
    }

    useEffect(() => {
        scrollToElement()
        window.addEventListener("scroll", function () {
            scrollToElement()
        })
    }, [])

    return (
        <Fragment>
            <HomeHeaderFold
                title={entryContent.title}
                subtitle={entryContent.subtitle}
                image={<InstantIcon />}
                fallBack={<AccountBlur className="blur" />}
            />

            <AnimationTextRight
                title={yourMoney.title}
                subtitle={yourMoney.subtitle}
                fallBack={<PhoneBlur className="blur" />}
                animationUrl={yourMoney.animation}
                buttonName={yourMoney.buttonName}
                buttonUrl={yourMoney.buttonUrl}
                image={<AccountIcon />}
                isWebButton={true}
            />

            <IndetificationDocument />

            <AnimationTextLeft
                title={phoneDebit.title}
                subtitle={phoneDebit.subtitle}
                buttonName={phoneDebit.buttonName}
                buttonUrl={phoneDebit.buttonUrl}
                fallBack={<PhoneBlur className="blur" />}
                animationUrl={phoneDebit.animation}
                image={<ReceiveIcon />}
            />

            <CTAUk />
        </Fragment>
    )
}

export default AccountUk
